exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-kickstage-ai-manifesto-js": () => import("./../../../src/pages/about-us/kickstage-ai-manifesto.js" /* webpackChunkName: "component---src-pages-about-us-kickstage-ai-manifesto-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-application-js": () => import("./../../../src/pages/open-application.js" /* webpackChunkName: "component---src-pages-open-application-js" */),
  "component---src-pages-our-work-finance-industry-js": () => import("./../../../src/pages/our-work/finance-industry.js" /* webpackChunkName: "component---src-pages-our-work-finance-industry-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-our-work-kickstage-develops-ai-powered-chatbot-for-dutydingo-js": () => import("./../../../src/pages/our-work/kickstage-develops-ai-powered-chatbot-for-dutydingo.js" /* webpackChunkName: "component---src-pages-our-work-kickstage-develops-ai-powered-chatbot-for-dutydingo-js" */),
  "component---src-pages-our-work-kickstage-develops-mymunet-an-advanced-iot-platform-for-jakob-muller-group-js": () => import("./../../../src/pages/our-work/kickstage-develops-mymunet-an-advanced-iot-platform-for-jakob-muller-group.js" /* webpackChunkName: "component---src-pages-our-work-kickstage-develops-mymunet-an-advanced-iot-platform-for-jakob-muller-group-js" */),
  "component---src-pages-our-work-manufacturing-industry-js": () => import("./../../../src/pages/our-work/manufacturing-industry.js" /* webpackChunkName: "component---src-pages-our-work-manufacturing-industry-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */)
}

